import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import Header from '../../components/Header';
import Document from '../../components/Document';

const DocCenter = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const userCompany = user["permissions/companies"].toLowerCase();
  const [documents, setDocuments] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState(userCompany);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [seeAll, setSeeAll] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/fileinfo`)
      .then(response => response.json())
      .then(data => {
        if (seeAll) {
          setDocuments(data);
        } else {
          setDocuments(data.filter((file) => file.name.includes('.pdf')));
        }
      });
  }, [selectedCompanyName, seeAll]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}/api/all/companies`)
      .then((response) => {
        console.log(response.data);
        setCompanies(response.data);
      });
  }, []);

  const handleDeleteDocument = (documentId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete " + documentId + "?");
    if (confirmDelete) {
      axios.delete(`${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/delete/${documentId}`)
        .then(() => {
          console.log(`Deleting document with ID: ${documentId}`);
          setDocuments((prevDocuments) => prevDocuments.filter((doc) =>  doc.name !== documentId));
        })
        .catch((error) => {
          console.error(`Error deleting document with ID: ${documentId}`, error);
        });
    }
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  if (!isAuthenticated) {
    return <div>Not Authenticated</div>;
  }
  return (
    <Box m="20px">
      <Header title="DOCUMENT CENTER" subtitle="View your documents" />
      {user['permissions/roles'] === 'admin' && (
        <div>
          <input
            type="checkbox"
            checked={seeAll}
            onChange={() => setSeeAll(!seeAll)}
          />
          <label htmlFor="sendEmailNotification">See All Files</label>
        </div>
      )}
      {user['permissions/roles'] === 'admin' && (
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel id="company-select-label">Company</InputLabel>
          <Select
            labelId="company-select-label"
            value={selectedCompany}
            onChange={(e) => {
              setSelectedCompany(e.target.value);
              setSelectedCompanyName(companies[e.target.value - 1].company);
            }}
            label={selectedCompanyName}
          >
            {companies.map((company) => (
              <MenuItem key={company._id} value={company._id}>
                {company.company}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} columns={10}>
          {documents.map((data) => (
            <Grid xs={2} key={data.id}>
              <Box>
                <Document title={data.name} fileType={data.name} company={selectedCompanyName} />
                {user['permissions/roles'] === 'admin' && (
                  <Button sx={{ marginTop: "-25px", left: "145px" }} onClick={() => handleDeleteDocument(data.name)}>Delete</Button>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default DocCenter;
