// import React, { useEffect, useState } from 'react';
// import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
// import { Box, Paper, Dialog, DialogTitle, DialogContent, List, ListItem, Typography } from "@mui/material";
// import { useAuth0 } from "@auth0/auth0-react";
// import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
// import { useMediaQuery } from 'react-responsive'
// import Skeleton from '@mui/material/Skeleton';
// import axios from 'axios';


// const TopHostsBarChart = (props) => {
//     const { user } = useAuth0();
//     const [chartData, setChartData] = useState([]);
//     const [selectedHost, setSelectedHost] = useState(null);
//     const [hostVulnerabilities, setHostVulnerabilities] = useState([]);
//     const [open, setOpen] = useState(false);
//     const [paperElevation, setPaperElevation] = useState(3);
//     const { border, data, date, company, godLoading, forReport } = props;
//     const isMobile = useMediaQuery({ query: `(max-width: 900px)` });
//     const [loading, setLoading] = useState(false);

  


//     const handleBarClick = async (data) => {
//         const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/${company}/visualizations/${date}/vulns/${data.host}`);
//         console.log(`${process.env.REACT_APP_APIURL}/api/${company}/visualizations/${date}/vulns/${data.host}`)
//         setSelectedHost(data.host);
//         setHostVulnerabilities(response.data);
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const columns = [
//         { field: 'id', headerName: 'ID', width: '100' },
//         { field: 'Protocol', headerName: 'Protocol', width: '100' },
//         { field: 'Plugin ID', headerName: 'Plugin ID', width: '100'},
//         { field: 'Risk', headerName: 'Risk', width: '100'},
//         { field: 'Name', headerName: 'Name', width: '300',  editable: true},
//         // ... add more columns as per your data structure
//     ];


//     const isIpAddress = (host) => {
//         const ipRegex = /^(25[0-5]|2[0-4]\d|[01]?\d?\d)(\.(25[0-5]|2[0-4]\d|[01]?\d?\d)){3}$/;
//         return ipRegex.test(host);
//       };
      
//       const hostFormatter = (host) => {
//         // If host matches IP regex, return as is
//         if (isIpAddress(host)) {
//           return host;
//         }
      
//         // Otherwise, assume hostname and return only the PC name portion
//         const parts = host.split('.');
//         return parts[0] || host; // Fallback if something unexpected happens
//       };


// if (!forReport){
//     return (
//         <div>
//         {isMobile ? (<Box  sx={{ width: 350, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
//         {/* <Paper elevation={paperElevation}> */}
//             <div style={{ textAlign: 'center', padding: '20px' }}>
//                 {godLoading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :  <Typography variant='h3' fontWeight={"bold"} >Top 5 Most Vulnerable Hosts </Typography>}
//                { godLoading ?       <Skeleton variant="rounded" height={150} />
// :  <BarChart
//   layout="vertical"
//   width={border ? 1100 : 1760}
//   height={300}
//   data={data}
//   margin={{ top: 15, right: 0, left: 100, bottom: 2 }}
// >
//   <CartesianGrid strokeDasharray="3 3" />
//   <XAxis type="number" />
//   <YAxis
//         type="category"
//         dataKey="host"
//         tickFormatter={hostFormatter}
//         style={{ fontWeight: 'bold' }}
//       />
//   <Tooltip />
//   <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} cursor="pointer" />
// </BarChart>}
//             </div>
//         {/* </Paper> */}
    
//         <Dialog 
// onClose={handleClose} 
// open={open} 
// maxWidth="md" 
// fullWidth 
// PaperProps={{ style: { width: '95%' } }}
// >
// <DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
// <DialogContent>
//     <DataGrid 
//           components={{
//         Toolbar: GridToolbarContainer,
//     }}
//     componentsProps={{
//         toolbar: {
//             children: [
//                 <GridToolbarQuickFilter />,
//                 <GridToolbarExport />
//             ],
//         },
//     }}
//         rows={hostVulnerabilities.map((vuln, index) => ({ ...vuln, id: index }))} 
//         columns={columns} 
//         pageSize={50} 
//         autoHeight
        
//     />
// </DialogContent>
// </Dialog>

//     </Box>

//         ) : (<Box  sx={border ? { width: 1220, height: 300 } : { width: 1760, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
//         {/* <Paper elevation={!border ? -1: paperElevation}> */}
//             <div style={{ textAlign: 'center', padding: '20px', zIndex: '9999' }}>
//             {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> :  <Typography variant='h3' fontWeight={"bold"} >Top 5 Most Vulnerable Hosts </Typography>}
//                 {godLoading ? <Skeleton variant="rounded" height={250} /> :
//              <BarChart
//   layout="vertical"
//   width={border ? 1100 : 1760}
//   height={300}
//   data={data}
//   margin={{ top: 15, right: 0, left: 100, bottom: 2 }}
// >
//   <CartesianGrid strokeDasharray="3 3" />
//   <XAxis type="number" />
//   <YAxis
//         type="category"
//         dataKey="host"
//         tickFormatter={hostFormatter}
//         style={{ fontWeight: 'bold' }}
//       />
//   <Tooltip />
//   <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} cursor="pointer" />
// </BarChart>
// }
//             </div>
//         {/* </Paper> */}
    
//         <Dialog 
// onClose={handleClose} 
// open={open} 
// maxWidth="md" 
// fullWidth 
// PaperProps={{ style: { width: '95%', height: "90%" } }}
// >
// <DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
// <DialogContent>
//     <DataGrid 
//           components={{
//         Toolbar: GridToolbarContainer,
//     }}
//     componentsProps={{
//         toolbar: {
//             children: [
//                 <GridToolbarQuickFilter />,
//                 <GridToolbarExport />
                
//             ],
//         },
//     }}
//         rows={hostVulnerabilities.map((vuln, index) => ({ ...vuln, id: index }))} 
//         columns={columns} 
//         pageSize={50} 
//         autoHeight={false} // Disable auto height for the grid
//     />
// </DialogContent>
// </Dialog>

//     </Box>

//         )}
//         </div>

//     ) 
// } 

// else return(
        

//         <div>
//         {isMobile ? (<Box  sx={{ width: 580, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
//         {/* <Paper elevation={paperElevation}> */}
//         <div style={{ textAlign: 'center', padding: '20px', zIndex: '9999' }}>
//         { godLoading ?       <Skeleton variant="rounded" height={150} />
// :  <BarChart
//   layout="vertical"
//   width={border ? 1190 : 1760} 
//   height={500}
//   data={data}
//   margin={{ top: 10, right: 100, left: 80, bottom: 10 }}
//   >
//   <CartesianGrid strokeDasharray="3 3" />
//   <XAxis type="number" style={{fontWeight: 'bold', fontSize: '18px'}} />
//   <YAxis
//         type="category"
//         dataKey="host"
//         tickFormatter={hostFormatter}
//         // tick={{ fontSize: (tick) => tick.length > 10 ? 14 : 18, fontWeight: 'bolder' }}
//         style={{ fontWeight: 'bolder', fontSize: '18px' }}
//       />
//   <Tooltip />
//   <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} cursor="pointer" />
// </BarChart>}
//             </div>
//         {/* </Paper> */}
    
//         <Dialog 
// onClose={handleClose} 
// open={open} 
// maxWidth="md" 
// fullWidth 
// PaperProps={{ style: { width: '95%' } }}
// >
// <DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
// <DialogContent>
//     <DataGrid 
//           components={{
//         Toolbar: GridToolbarContainer,
//     }}
//     componentsProps={{
//         toolbar: {
//             children: [
//                 <GridToolbarQuickFilter />,
//                 <GridToolbarExport />
//             ],
//         },
//     }}
//         rows={hostVulnerabilities.map((vuln, index) => ({ ...vuln, id: index }))} 
//         columns={columns} 
//         pageSize={50} 
//         autoHeight
        
//     />
// </DialogContent>
// </Dialog>

//     </Box>

//         ) : (<Box  sx={border ? { width: 1220, height: 300 } : { width: 1760, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
//         {/* <Paper elevation={!border ? -1: paperElevation}> */}
//             <div style={{ textAlign: 'center', padding: '20px' }}>
//                 {godLoading ? <Skeleton variant="rounded" height={250} /> :
//             <BarChart
//   layout="vertical"
//   width={border ? 1190 : 1760} 
//   height={600}
//   data={data}
//   margin={{ top: 10, right: 100, left: 80, bottom: 10 }}
//   >
//   <CartesianGrid strokeDasharray="3 3" />
//   <XAxis type="number" style={{fontWeight: 'bold', fontSize: '23px'}} />
//   <YAxis
//         type="category"
//         dataKey="host"
//         tickFormatter={hostFormatter}
//         tick={{ fontSize: (tick) => tick.length >= 10 ? 14 : 18, fontWeight: 'bolder' }}
//         // style={{ fontWeight: 'bold', fontSize: '50px' }}
//       /> 
//   <Tooltip />
//   <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} cursor="pointer" />
// </BarChart>
// }
//             </div>
//         {/* </Paper> */}
    
//         <Dialog 
// onClose={handleClose} 
// open={open} 
// maxWidth="md" 
// fullWidth 
// PaperProps={{ style: { width: '95%', height: "90%" } }}
// >
// <DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
// <DialogContent>
//     <DataGrid 
//           components={{
//         Toolbar: GridToolbarContainer,
//     }}
//     componentsProps={{
//         toolbar: {
//             children: [
//                 <GridToolbarQuickFilter />,
//                 <GridToolbarExport />
                
//             ],
//         },
//     }}
//         rows={hostVulnerabilities.map((vuln, index) => ({ ...vuln, id: index }))} 
//         columns={columns} 
//         pageSize={50} 
//         autoHeight={false} // Disable auto height for the grid
//     />
// </DialogContent>
// </Dialog>

//     </Box>

//         )}
//         </div>
        
    



//     )

    
// };

// export default TopHostsBarChart;
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from 'recharts';
import {
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { useMediaQuery } from 'react-responsive';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';

const TopHostsBarChart = (props) => {
  const { user } = useAuth0();
  const [chartData, setChartData] = useState([]);
  const [selectedHost, setSelectedHost] = useState(null);
  const [hostVulnerabilities, setHostVulnerabilities] = useState([]);
  const [open, setOpen] = useState(false);
  const [paperElevation, setPaperElevation] = useState(3);
  const { border, data, date, company, godLoading, forReport } = props;
  // This hook is still used for the non-report branch
  const isMobile = useMediaQuery({ query: `(max-width: 900px)` });
  const [loading, setLoading] = useState(false);

  const handleBarClick = async (data) => {
    const response = await axios.get(
      `${process.env.REACT_APP_APIURL}/api/${company}/visualizations/${date}/vulns/${data.host}`
    );
    console.log(
      `${process.env.REACT_APP_APIURL}/api/${company}/visualizations/${date}/vulns/${data.host}`
    );
    setSelectedHost(data.host);
    setHostVulnerabilities(response.data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: '100' },
    { field: 'Protocol', headerName: 'Protocol', width: '100' },
    { field: 'Plugin ID', headerName: 'Plugin ID', width: '100' },
    { field: 'Risk', headerName: 'Risk', width: '100' },
    { field: 'Name', headerName: 'Name', width: '300', editable: true },
    // ... add more columns as per your data structure
  ];

  const isIpAddress = (host) => {
    const ipRegex =
      /^(25[0-5]|2[0-4]\d|[01]?\d?\d)(\.(25[0-5]|2[0-4]\d|[01]?\d?\d)){3}$/;
    return ipRegex.test(host);
  };

  const hostFormatter = (host) => {
    // If host matches IP regex, return as is
    if (isIpAddress(host)) {
      return host;
    }
    // Otherwise, assume hostname and return only the PC name portion
    const parts = host.split('.');
    return parts[0] || host; // Fallback if something unexpected happens
  };

  // *******************
  // Tick styling helpers
  // *******************

  // This function computes a font size based on the host value.
  // Adjust the numeric values as desired.
  const computeFontSize = (host) => {
    let fontSize = 18;
    // Increase font size if the host is an IP address
    if (isIpAddress(host)) {
      fontSize += 3; // e.g. 21px
    }
    // Decrease font size if all characters are uppercase
    if (host === host.toUpperCase()) {
      fontSize -= 2;
    }
    // Adjust based on the length of the host string:
    if (host.length < 6) {
      fontSize += 2;
    } else if (host.length > 12) {
      fontSize -= 2;
    }
    return fontSize;
  };

  // Custom tick renderer for the Y axis
  const renderCustomizedYAxisTick = (props) => {
    const { x, y, payload } = props;
    const host = payload.value;
    const fontSize = computeFontSize(host);
    return (
      <text
        x={x - 10}
        y={y + 5}
        textAnchor="end"
        fill="#666"
        style={{ fontSize: `${fontSize}px`, fontWeight: 'bolder' }}
      >
        {hostFormatter(host)}
      </text>
    );
  };

  // *****************************************************
  // Conditional rendering for non-report vs. report views
  // *****************************************************

  if (!forReport) {
    return (
      <div>
        {isMobile ? (
          <Box
            sx={{ width: 350, height: 150 }}
            onMouseEnter={() => setPaperElevation(5)}
            onMouseLeave={() => setPaperElevation(3)}
          >
            <div style={{ textAlign: 'center', padding: '20px' }}>
              {godLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              ) : (
                <Typography variant="h3" fontWeight="bold">
                  Top 5 Most Vulnerable Hosts
                </Typography>
              )}
              {godLoading ? (
                <Skeleton variant="rounded" height={150} />
              ) : (
                <BarChart
                  layout="vertical"
                  width={border ? 1100 : 1760}
                  height={300}
                  data={data}
                  margin={{ top: 15, right: 0, left: 100, bottom: 2 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis
                    type="category"
                    dataKey="host"
                    tickFormatter={hostFormatter}
                    style={{ fontWeight: 'bold' }}
                  />
                  <Tooltip />
                  <Bar
                    dataKey="count"
                    fill="#1491CB"
                    onClick={handleBarClick}
                    cursor="pointer"
                  />
                </BarChart>
              )}
            </div>

            <Dialog
              onClose={handleClose}
              open={open}
              maxWidth="md"
              fullWidth
              PaperProps={{ style: { width: '95%' } }}
            >
              <DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
              <DialogContent>
                <DataGrid
                  components={{
                    Toolbar: GridToolbarContainer,
                  }}
                  componentsProps={{
                    toolbar: {
                      children: [<GridToolbarQuickFilter />, <GridToolbarExport />],
                    },
                  }}
                  rows={hostVulnerabilities.map((vuln, index) => ({
                    ...vuln,
                    id: index,
                  }))}
                  columns={columns}
                  pageSize={50}
                  autoHeight
                />
              </DialogContent>
            </Dialog>
          </Box>
        ) : (
          <Box
            sx={border ? { width: 1220, height: 300 } : { width: 1760, height: 300 }}
            onMouseEnter={() => setPaperElevation(5)}
            onMouseLeave={() => setPaperElevation(3)}
          >
            <div style={{ textAlign: 'center', padding: '20px', zIndex: '9999' }}>
              {godLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }}
                />
              ) : (
                <Typography variant="h3" fontWeight="bold">
                  Top 5 Most Vulnerable Hosts
                </Typography>
              )}
              {godLoading ? (
                <Skeleton variant="rounded" height={250} />
              ) : (
                <BarChart
                  layout="vertical"
                  width={border ? 1100 : 1760}
                  height={300}
                  data={data}
                  margin={{ top: 15, right: 0, left: 100, bottom: 2 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis
                    type="category"
                    dataKey="host"
                    tickFormatter={hostFormatter}
                    style={{ fontWeight: 'bold' }}
                  />
                  <Tooltip />
                  <Bar
                    dataKey="count"
                    fill="#1491CB"
                    onClick={handleBarClick}
                    cursor="pointer"
                  />
                </BarChart>
              )}
            </div>

            <Dialog
              onClose={handleClose}
              open={open}
              maxWidth="md"
              fullWidth
              PaperProps={{ style: { width: '95%', height: '90%' } }}
            >
              <DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
              <DialogContent>
                <DataGrid
                  components={{
                    Toolbar: GridToolbarContainer,
                  }}
                  componentsProps={{
                    toolbar: {
                      children: [<GridToolbarQuickFilter />, <GridToolbarExport />],
                    },
                  }}
                  rows={hostVulnerabilities.map((vuln, index) => ({
                    ...vuln,
                    id: index,
                  }))}
                  columns={columns}
                  pageSize={50}
                  autoHeight={false} // Disable auto height for the grid
                />
              </DialogContent>
            </Dialog>
          </Box>
        )}
      </div>
    );
  } else {
    // *********************
    // Report View (forReport)
    // *********************
    // Removed the mobile option by providing only one version (desktop version)
    return (
      <div>
        <Box
          sx={border ? { width: 1220, height: 300 } : { width: 1760, height: 300 }}
          onMouseEnter={() => setPaperElevation(5)}
          onMouseLeave={() => setPaperElevation(3)}
        >
          <div style={{ textAlign: 'center', padding: '20px' }}>
            {godLoading ? (
              <Skeleton variant="rounded" height={250} />
            ) : (
              <BarChart
                layout="vertical"
                width={border ? 1190 : 1760}
                height={600}
                data={data}
                margin={{ top: 10, right: 100, left: 80, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" style={{ fontWeight: 'bold', fontSize: '23px' }} />
                <YAxis
                  type="category"
                  dataKey="host"
                  // Use custom tick renderer for dynamic font sizing based on label content:
                  tick={renderCustomizedYAxisTick}
                />
                <Tooltip />
                <Bar
                  dataKey="count"
                  fill="#1491CB"
                  onClick={handleBarClick}
                  cursor="pointer"
                />
              </BarChart>
            )}
          </div>

          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="md"
            fullWidth
            PaperProps={{ style: { width: '95%', height: '90%' } }}
          >
            <DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
            <DialogContent>
              <DataGrid
                components={{
                  Toolbar: GridToolbarContainer,
                }}
                componentsProps={{
                  toolbar: {
                    children: [<GridToolbarQuickFilter />, <GridToolbarExport />],
                  },
                }}
                rows={hostVulnerabilities.map((vuln, index) => ({
                  ...vuln,
                  id: index,
                }))}
                columns={columns}
                pageSize={50}
                autoHeight={false} // Disable auto height for the grid
              />
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    );
  }
};

export default TopHostsBarChart;
