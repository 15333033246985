import * as React from "react";
import { Typography, Box, useTheme, CardActionArea } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { tokens } from "../theme";
import Paper from '@mui/material/Paper';
import { useAuth0 } from "@auth0/auth0-react";


const Document = ({ title, fileType, company }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, isAuthenticated, isLoading } = useAuth0();
    const userCompany = user["permissions/companies"];

// const handleClick = () => {
//     fetch()
// fetch(process.env.REACT_APP_APIURL + "/api/" + userCompany + "/fileinfo")

// }


  return (
    <Box
      sx={{
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 210,
          height: 300,
          borderRadius: 1,
        },
        
      }}
    >   
          <a href={process.env.REACT_APP_APIURL + "/" + company + "/download/"+ title} download>

      <Card sx={{ maxWidth: 210 }} elevation={5}>
      
      <CardActionArea>

      <CardMedia
        sx={{ height: 230 }}
        image="/assets/arg-full-color.png"
        title={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {fileType}
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>
    </a>
    </Box>
  );
};

export default Document;